<template>
  <v-card>
    <v-card-title>
      <slot name="header"></slot>
    </v-card-title>
    <v-card-subtitle>
      <slot name="subtitle"></slot>
    </v-card-subtitle>
    <v-card-text>
      <v-switch
        :success="true"
        :label="`${currency}`"
        @change="changeCurrency"
        false-value="USD"
        true-value="RUB"
        v-model="currency"
      ></v-switch>
      <div ref="chart" class="chart"></div>
    </v-card-text>
    <v-card-subtitle>
      <div class="legend">
        <span class="mdi mdi-circle blue--text"></span>
        Прибыль {{ data.totalProfit[currency].toFixed(2) }}
        <span class="mdi mdi-circle red--text"></span>
        Выведено {{ data.totalUserPayments[currency].toFixed(2) }}
        <span class="mdi mdi-circle brown--text"></span>
        Расходы {{ data.totalInvoices[currency].toFixed(2) }}
        <span class="mdi mdi-circle orange--text"></span>
        Пополнено {{ data.totalEnrollments[currency].toFixed(2) }}
      </div>
    </v-card-subtitle>
  </v-card>
</template>

<script>
import Chartist from "chartist";
function extractSummary(chartObject, dataObject, currency) {
  return {
    ...chartObject,
    totalEnrollments: dataObject.totalEnrollments,
    totalUserPayments: dataObject.totalUserPayments,
    totalProfit: dataObject.totalProfit,
    data: {
      labels: dataObject.daysOfMonth,
      series: [
        dataObject.profit[currency],
        dataObject.userPayments[currency],
        dataObject.enrollments[currency],
        dataObject.invoices[currency]
      ]
    },
    options: {
      ...chartObject.options,
      high: dataObject.high[currency],
      low: dataObject.low[currency]
    }
  };
}

export default {
  name: "chart",
  props: ["data"],
  data: () => ({
    chart: null,
    currency: "RUB",
    chartObject: {
      totalEnrollments: {
        dollars: 0,
        roubles: 0
      },
      totalUserPayments: {
        dollars: 0,
        roubles: 0
      },
      totalProfit: {
        dollars: 0,
        roubles: 0
      },
      totalInvoices: {
        dollars: 0,
        roubles: 0
      },
      options: {
        showArea: false,
        height: 600,
        axisX: {
          showGrid: false
        },
        lineSmooth: true,
        showLine: true,
        showPoint: true,
        fullWidth: true,
        chartPadding: {
          right: 50
        }
      },
      responsiveOptions: [
        [
          "screen and (max-width: 640px)",
          {
            axisX: {
              labelInterpolationFnc(value) {
                return value[0];
              }
            }
          }
        ]
      ]
    }
  }),
  watch: {
    currency() {
      this.init();
    }
  },
  methods: {
    changeCurrency(val) {
      console.log(val);
    },
    init() {
      const data = extractSummary(this.chartObject, this.data, this.currency);
      console.log(data);
      this.chart = new Chartist.Line(
        this.$refs.chart,
        {
          labels: data.data.labels,
          series: data.data.series
        },
        data.options,
        this.data.responsiveOptions
      );

      let seq = 0,
        delays = 10,
        durations = 500;
      this.chart.on("created", function() {
        seq = 0;
      });

      this.chart.on("draw", function(data) {
        seq++;

        if (data.type === "line") {
          // If the drawn element is a line we do a simple opacity fade in. This could also be achieved using CSS3 animations.
          data.element.animate({
            opacity: {
              // The delay when we like to start the animation
              begin: seq * delays + 1000,
              // Duration of the animation
              dur: durations,
              // The value where the animation should start
              from: 0,
              // The value where it should end
              to: 1
            }
          });
        } else if (data.type === "label" && data.axis === "x") {
          data.element.animate({
            y: {
              begin: seq * delays,
              dur: durations,
              from: data.y + 100,
              to: data.y,
              // We can specify an easing function from Chartist.Svg.Easing
              easing: "easeOutQuart"
            }
          });
        } else if (data.type === "label" && data.axis === "y") {
          data.element.animate({
            x: {
              begin: seq * delays,
              dur: durations,
              from: data.x - 100,
              to: data.x,
              easing: "easeOutQuart"
            }
          });
        } else if (data.type === "point") {
          data.element.animate({
            x1: {
              begin: seq * delays,
              dur: durations,
              from: data.x - 10,
              to: data.x,
              easing: "easeOutQuart"
            },
            x2: {
              begin: seq * delays,
              dur: durations,
              from: data.x - 10,
              to: data.x,
              easing: "easeOutQuart"
            },
            opacity: {
              begin: seq * delays,
              dur: durations,
              from: 0,
              to: 1,
              easing: "easeOutQuart"
            }
          });
        } else if (data.type === "grid") {
          // Using data.axis we get x or y which we can use to construct our animation definition objects
          var pos1Animation = {
            begin: seq * delays,
            dur: durations,
            from: data[data.axis.units.pos + "1"] - 30,
            to: data[data.axis.units.pos + "1"],
            easing: "easeOutQuart"
          };

          var pos2Animation = {
            begin: seq * delays,
            dur: durations,
            from: data[data.axis.units.pos + "2"] - 100,
            to: data[data.axis.units.pos + "2"],
            easing: "easeOutQuart"
          };

          var animations = {};
          animations[data.axis.units.pos + "1"] = pos1Animation;
          animations[data.axis.units.pos + "2"] = pos2Animation;
          animations["opacity"] = {
            begin: seq * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: "easeOutQuart"
          };
          data.element.animate(animations);
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style>
.chart {
  display: block;
  height: 600px;
}
.ct-series-a .ct-line,
.ct-series-a .ct-point {
  stroke: #2196f3;
}

.ct-series-b .ct-line,
.ct-series-b .ct-point {
  stroke: #f44336;
}

.ct-series-c .ct-line,
.ct-series-c .ct-point {
  stroke: #ff9800;
}
</style>
