<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>
          Созданные промо
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="bonuses"
            @update:page="loadPage"
            :server-items-length="countRow"
            :loading="loading"
            loading-text="Загрузка... Пожалуйста подождите"
            :items-per-page="25"
          >
            <template v-slot:item.user="{ item }">
              <router-link tag="div" :to="`/users/${item.cards.id}`">
                <v-btn color="primary"> {{ item.cards.nick }}</v-btn>
              </router-link>
            </template>
            <template v-slot:item.amount="{ item }">
              {{ (item.amount / 100).toFixed(2) }}
            </template>
            <template v-slot:item.minDep="{ item }">
              {{ (item.minDep / 100).toFixed(2) }}
            </template>
            <template v-slot:item.minDepPerDay="{ item }">
              {{ (item.minDepPerDay / 100).toFixed(2) }}
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ item.createdAt | moment("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template v-slot:item.date_start="{ item }">
              {{ item.date_start | moment("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template v-slot:item.date_expire="{ item }">
              {{ item.date_expire | moment("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon @click="deletePromo(item.id)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import BonusServices from "@/services/bonus.service";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Bonuses",
  data: () => ({
    headers: [
      { text: "id", value: "id" },
      { text: "Пользователь", value: "user" },
      { text: "Код", value: "code" },
      { text: "Источник", value: "source.value" },
      { text: "Тип бонуса", value: "bonusTypes.value" },
      { text: "Сумма", value: "amount" },
      { text: "Кол-во использований", value: "counter" },
      { text: "Лимит использований", value: "limit" },
      { text: "Минимальный депозит", value: "minDep" },
      { text: "Минимальный депозит за сегодня", value: "minDepPerDay" },
      { text: "Время создания", value: "createdAt" },
      { text: "Начинается с", value: "date_start" },
      { text: "Действует до", value: "date_expire" },
      { text: "Действия", value: "actions" }
    ],
    search: ""
  }),
  computed: {
    ...mapGetters("bonuses", ["bonuses", "loading", "countRow"])
  },
  watch: {
    search(word) {
      if (word.length > 3) {
        this.findMany({
          include: {
            cards: true,
            bonusTypes: true,
            source: true
          },
          orderBy: {
            id: "desc"
          },
          where: {
            code: {
              contains: word
            }
          },
          take: 15
        });
      } else {
        this.loadPage();
      }
    }
  },
  methods: {
    ...mapActions("bonuses", ["findMany", "count"]),
    loadPage(page) {
      this.loadBonuses(page);
    },
    loadBonuses(page = 1) {
      this.count();
      this.findMany({
        orderBy: {
          id: "desc"
        },
        include: {
          cards: true,
          bonusTypes: true,
          source: true
        },
        skip: (page - 1) * 15,
        take: 15
      });
    },
    deletePromo(id) {
      BonusServices.delete({
        where: {
          id: parseInt(id)
        }
      }).then(
        () => {
          this.$noty.success("Успешно");
          this.loadBonuses();
        },
        error => this.$noty.error(error.response.message)
      );
    }
  },
  created() {
    this.loadBonuses();
  }
};
</script>

<style scoped></style>
